<template>
  <!--begin::Contract Listing-->
  <div class="contract-template">
    <PageHeaderCount
      moduleType="contract"
      :dataLoading.sync="dataLoading"
      :statusList.sync="statusList"
      allkey="all_contracts"
      countkey="contract_status_count"
    ></PageHeaderCount>
    <ListingTemplate :customClass="'contract-listing'">
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template>
              <v-col cols="4" class="pt-0">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_contracts
                          }}</template>
                          <template v-else>{{
                            item.contract_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-col>
              <v-col cols="8" class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('contract:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="customerDialog = true"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="!isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'contract'
                        }
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu content-class="custom-menu-list" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="cols.fixed || dataLoading"
                          color="cyan"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <PageTips module="contract"></PageTips>
              </v-col>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Contract"
          :basicSearchFields="['barcode', 'reference', 'subject']"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <!-- <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 60vh; position: relative"
        > -->
        <ListingTable
          :columnCount.sync="defaultColDefs.length"
          :dataLoading.sync="dataLoading"
          :rowData.sync="rowData"
        >
          <template v-slot:thead>
            <thead>
              <tr>
                <template v-for="cols in defaultColDefs">
                  <th
                    v-if="cols.visible"
                    :key="cols.id"
                    class="simple-table-th"
                    :class="{ 'checkbox-width-limit': cols.checkbox }"
                    :style="{ 'max-width': cols.width }"
                  >
                    <template v-if="cols.checkbox">
                      <v-checkbox
                        dense
                        :disabled="dataLoading"
                        v-model="selectedAll"
                        color="cyan"
                        class="hide-margin"
                        hide-details
                      ></v-checkbox>
                    </template>
                    <template v-else>
                      <div
                        v-if="cols.sortable"
                        class="simple-table-text sortable"
                        v-on:click="
                          !dataLoading
                            ? updateSorting({
                                field: cols.field,
                                sort: cols.sort
                              })
                            : 'javascript:void(0)'
                        "
                      >
                        {{ cols.headerName }}
                      </div>
                      <div v-else class="simple-table-text">
                        {{ cols.headerName }}
                      </div>
                      <template v-if="cols.sort">
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'asc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-ascending</v-icon
                        >
                        <v-icon
                          class="simple-table-sort"
                          v-if="cols.sort == 'desc'"
                          small
                          right
                          color="cyan"
                          >mdi-sort-descending</v-icon
                        >
                      </template>
                    </template>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom">
              <template v-if="!dataLoading">
                <template v-if="!lodash.isEmpty(rowData)">
                  <tr v-for="(data, index) in rowData" :key="index" link>
                    <template v-for="cols in defaultColDefs">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="rowClicked(data)"
                      >
                        <template v-if="cols.field == 'barcode'">
                          <Barcode :barcode="data.barcode"></Barcode>
                        </template>
                        <template v-else-if="cols.field == 'basic_detail'">
                          <div class="contract-listing">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Subject: </b>{{ data.subject }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>SOled Job No.: </b>
                              <template v-if="data.soled_job_no">
                                {{ data.soled_job_no }}
                              </template>
                              <template v-else>
                                <em class="text--secondary"
                                  >No SOled Job No.</em
                                >
                              </template>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Contract Type: </b>{{ data.contract_type }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Contract Value: </b
                              >{{
                                $accountingJS.formatMoney(data.contract_value)
                              }}
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <template v-if="data.renewed">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  class="mx-2 custom-status font-weight-600 custom-grey-border text-uppercase justify-center"
                                  color="cyan"
                                  text-color="white"
                                  label
                                  small
                                >
                                  Renewed
                                </v-chip>
                              </template>
                              <span>Warranty Renewed</span>
                            </v-tooltip>
                          </template>
                          <CustomStatus
                            endpoint="contracts/status"
                            small
                            :status.sync="data.status"
                          ></CustomStatus>
                        </template>
                        <template v-else-if="cols.field == 'dates'">
                          <div class="contract-listing">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Start Date: </b>
                              <template v-if="data.start_date">{{
                                formatDate(data.start_date)
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No Start Date</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>End Date: </b>
                              <template v-if="data.end_date">{{
                                formatDate(data.end_date)
                              }}</template>
                              <template v-else
                                ><em class="text--secondary"
                                  >No End Date</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'project'">
                          <template v-if="data.project">
                            <div v-on:click.stop>
                              <Barcode
                                :barcode="data.project.barcode"
                                route="project.detail"
                                :id.sync="data.project.id"
                              ></Barcode>
                            </div>
                          </template>
                        </template>
                        <template v-else-if="cols.field == 'customer'">
                          <div
                            class="contract-listing-customer"
                            v-if="data.customer"
                          >
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.customer.default_person"
                            >
                              <b>Name: </b>
                              {{ data.customer.default_person.display_name }}
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.customer"
                            >
                              <b>Company: </b>
                              {{ data.customer.company_name }}
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.customer.default_person"
                            >
                              <b>Email: </b>
                              {{ data.customer.default_person.primary_email }}
                            </p>
                            <p
                              class="m-0 custom-nowrap-ellipsis"
                              v-if="data.customer.default_person"
                            >
                              <b>Phone: </b>
                              {{ data.customer.default_person.primary_phone }}
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data.sync="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data.sync="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="defaultColDefs.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no contract at the moment.
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </ListingTable>
        <!-- </perfect-scrollbar> -->
        <ListingFooter
          :dataLoading.sync="dataLoading"
          :showingFrom.sync="showingFrom"
          :showingTo.sync="showingTo"
          :totalRows.sync="totalRows"
          :currentPage.sync="currentPage"
          :totalPages.sync="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Contract</template>
          <template v-slot:body>
            <!-- <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 60vh; position: relative"
            > -->
            <v-container fluid class="pt-0 custom-search-filter">
              <v-row>
                <v-col md="6">
                  <DateRangePicker
                    v-model="dates"
                    prop_label="Dates Range"
                  ></DateRangePicker>
                </v-col>
                <template v-for="(field, index) in searchableArray">
                  <v-col md="6" :key="index" v-if="field != 'dates'">
                    <v-text-field
                      v-model.trim="listingSearch[field]"
                      dense
                      filled
                      hide-details
                      :label="getFilterLabel(field, 'Contract')"
                      solo
                      flat
                      clearable
                      @keydown.enter="filterRows"
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-container>
            <!-- </perfect-scrollbar> -->
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <template v-if="customerDialog">
          <CustomerDialog
            :customerDialog.sync="customerDialog"
            v-on:closeDialog="customerDialog = false"
            v-on:resetAll="customerDialog = false"
            v-on:selectCustomer="selectCustomer"
          ></CustomerDialog>
        </template>
        <template v-if="getPermission('contract:create')">
          <Dialog :commonDialog="importDialog">
            <template v-slot:title>Import Contract</template>
            <template v-slot:body>
              <v-form
                ref="contractImport"
                v-model="valid_import"
                lazy-validation
                v-on:submit.stop.prevent="importRows()"
              >
                <v-container fluid class="pt-0 custom-search-filter">
                  <v-btn
                    v-on:click="downloadSample()"
                    :disabled="dataLoading || loading_imported"
                    class="mx-2 custom-grey-border custom-bold-button"
                    color="cyan white--text"
                  >
                    Download Sample
                  </v-btn>
                  <v-file-input
                    :disabled="loading_imported"
                    :rules="[
                      value =>
                        !value ||
                        value.size < 5000000 ||
                        'File size should be less than 5 MB!'
                    ]"
                    v-on:change="handleFileUpload"
                    placeholder="Select Excel File"
                    color="cyan"
                    show-size
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  ></v-file-input>
                  <div>
                    <ul
                      v-if="success_message.length"
                      class="import-success-message"
                    >
                      <li
                        v-for="(message, index) in success_message"
                        :key="index"
                      >
                        {{ message }}
                      </li>
                    </ul>
                    <ul
                      v-if="error_message.length"
                      class="import-error-message"
                    >
                      <li
                        v-for="(message, index) in error_message"
                        :key="index"
                      >
                        {{ message }}
                      </li>
                    </ul>
                    <p
                      v-if="error_message.length"
                      class="m-0 font-size-18 font-weight-700 red--text text--lighten-1"
                    >
                      Please check excel file.
                    </p>
                  </div>
                </v-container>
              </v-form>
            </template>
            <template v-slot:action>
              <v-btn
                v-if="!excel_imported"
                :disabled="dataLoading || !valid_import || loading_imported"
                class="mx-2 custom-grey-border custom-bold-button"
                color="cyan white--text"
                v-on:click="importRows()"
              >
                Import
              </v-btn>
              <v-btn
                :disabled="loading_imported"
                class="mx-2 custom-grey-border custom-bold-button"
                v-on:click="importDialog = false"
              >
                Close
              </v-btn>
            </template>
          </Dialog>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Contract Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus";
import DateRangePicker from "@/view/pages/partials/DateRangePicker.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import JwtService from "@/core/services/jwt.service";
import { POST } from "@/core/services/store/request.module";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "contract-list",
  data() {
    return {
      importDialog: false,
      valid_import: true,
      excel_imported: false,
      dataLoading: false,
      loading_imported: false,
      success_message: [],
      error_message: [],
      customerDialog: false,
      exportLoading: false,
      dates: [],
      pageModule: "contract-listing",
      routeAPI: "contracts",
      routeName: "contract",
      routeDetailName: "contract.detail",
      status: "all",
      statusList: [],
      file: null,
      moreActions: [
        {
          title: "Export Contract(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export"
        },
        {
          title: "Import Contract(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import"
        },
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export"
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh"
        }
      ]
    };
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = undefined;
        }
      }
    }
  },
  components: {
    DateRangePicker,
    CustomerDialog,
    PageHeaderCount,
    draggable,
    CustomStatus,
    PageTips,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog
  },
  methods: {
    handleFileUpload(param) {
      this.file = param;
      this.excel_imported = false;
      this.success_message = [];
      this.error_message = [];
    },
    importRows() {
      const _this = this;
      const file = _this.file;
      if (!file) {
        alert("No file chosen");
        return;
      }
      const data = new FormData();
      _this.loading_imported = true;
      _this.excel_imported = false;
      _this.success_message = [];
      _this.error_message = [];
      data.append("excel", file);
      _this.$store
        .dispatch(POST, {
          url: "contracts/import",
          data: data
        })
        .then(({ data }) => {
          _this.success_message = data.success || [];
          _this.error_message = data.error || [];
          _this.excel_imported = true;
          _this.getRows();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loading_imported = false;
        });
    },
    downloadSample() {
      const token = JwtService.getToken();
      const url = this.$apiURL + "contracts/sample-download?token=" + token;
      window.open(url, "_target");
    },
    exportContract() {
      const token = JwtService.getToken();
      const url = this.$apiURL + "contracts/export?token=" + token;
      window.open(url, "_target");
    },
    selectCustomer(customer) {
      if (customer > 0) {
        this.$router.push(
          this.getDefaultRoute("contract.create", {
            query: { customer }
          })
        );
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.exportContract();
          break;
        case "export_current_view":
          _this.exportCurrentView("contract");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Contract"
      }
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "Contract #",
        field: "barcode",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px"
      },
      /*{
        headerName: "Project #",
        field: "project",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 1,
        width: "200px"
      },*/
      {
        headerName: "Basic Details",
        field: "basic_detail",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
        width: "300px"
      },
      {
        headerName: "Customer",
        field: "customer",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
        width: "300px"
      },
      {
        headerName: "Dates",
        field: "dates",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
        width: "300px"
      },
      {
        headerName: "Status",
        field: "status",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 3,
        width: "200px"
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 6,
        width: "170px"
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: true,
        order: 7,
        width: "170px"
      }
    ];

    _this.defaultColShow = _this.defaultColDefs.map(col => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  }
};
</script>
